import React from "react";

function About() {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-4xl font-bold mb-6">About Me</h1>
      <div className="prose max-w-2xl">
        <p>
          Hi, I'm a designer passionate about creating intuitive and
          user-friendly digital experiences. With a background in design and
          human-computer interaction, I strive to solve complex problems through
          thoughtful and innovative design solutions.
        </p>
        <p>
          I have experience designing interfaces and interactions of products
          for customers from a variety of industries. I’ve worked on web and
          mobile products for healthcare, advertising and marketing, travel,
          supply chain management, and semiconductor verticals.
        </p>
        <p>
          The intersection of technology and the humanities is where I operate
          and am in my element juggling the many aspects of product design: be
          it business, engineering, and design, always keeping in mind the needs
          of the user.
        </p>
      </div>
    </div>
  );
}

export default About;
