import React from "react";
import { motion } from "framer-motion";

function ProjectModal({ project, onClose }) {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.9 }}
        className="bg-white rounded-xl max-w-4xl w-full max-h-[99vh] overflow-auto p-6"
      >
        <div className="flex justify-between items-center mb-4">
          <h1 className="text-3xl font-bold">{project.name}</h1>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-800"
          >
            ✕
          </button>
        </div>

        <div
          className="prose max-w-none"
          dangerouslySetInnerHTML={{ __html: project.html }}
        />

        {project.slides && project.slides.length > 0 && (
          <div className="mt-6 grid md:grid-cols-3 gap-4">
            {project.slides.map((slide, index) => (
              <div key={index} className="text-center">
                <img
                  src={`/img/${slide.image}`}
                  alt={slide.text}
                  className="w-full rounded-lg shadow-md"
                />
                <p className="mt-2 text-sm text-gray-600">{slide.text}</p>
              </div>
            ))}
          </div>
        )}
      </motion.div>
    </div>
  );
}

export default ProjectModal;
