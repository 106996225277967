import React from "react";
import { motion } from "framer-motion";

function ProjectCard({ project, onClick }) {
  return (
    <motion.div
      className="bg-white shadow-lg rounded-lg overflow-hidden cursor-pointer hover:shadow-xl transition-all duration-300"
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.95 }}
      onClick={onClick}
    >
      <img
        src={`/img-webp/${project.img.replace(/\.(jpg|png)$/, ".webp")}`}
        alt={project.name}
        className="w-full h-48 object-cover"
      />
      <div className="p-4">
        <h2 className="text-xl font-bold text-gray-800">{project.name}</h2>
        <p className="text-gray-600">{project.caption}</p>
      </div>
    </motion.div>
  );
}

export default ProjectCard;
